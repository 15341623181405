import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
import { Button, Icon, Link, Pill, Select } from '@opengov/component-library/capital';
import { IconBlock, PatternExample, UsageBlock } from '../../../components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Size & Color`}</h2>
    <p>{`All icons are built on a 16 x 16 artboard. They are adjusted within the artboard based on their desired sized. For anything larger than 16 x 16, an illustration should be used.`}</p>
    <p>{`Icons are typically a single color. Though some icons have a 50% opacity shade built in for complex visuals, for example:`}</p>
    <IconBlock icon="chart-bullet" mdxType="IconBlock" />
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>Use icons at any other size than 16 x 16</p>
    <p>Stretch or distort icons</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Layout`}</h2>
    <h3>{`Button`}</h3>
    <PatternExample example={<>
    <p><Button mdxType="Button"><Icon icon="action-print" mdxType="Icon" /> Print</Button></p>
    <p><Pill icon="operator-equal" text="Filter equals" mdxType="Pill" /></p>
  </>} title="With text" description={"kdfsldkfjlskdjfsldkfjlskdf"} mdxType="PatternExample" />
    <PatternExample example={<Button mdxType="Button"><Icon icon="action-print" mdxType="Icon" /></Button>} title="Alone" description={"kdfsldkfjlskdjfsldkfjlskdf"} mdxType="PatternExample" />
    <h3>{`Select`}</h3>
    <PatternExample example={<div className="patternDemo-menu">
      <Select.Option prefixIcon={<Icon icon="action-print" mdxType="Icon" />} label="Print" />
      <Select.Option prefixIcon={<Icon icon="action-export" mdxType="Icon" />} label="Export" />
      <Select.Option prefixIcon={<Icon icon="action-delete" mdxType="Icon" />} label="Delete" />
    </div>} title="With text" description={"kdfsldkfjlskdjfsldkfjlskdf"} mdxType="PatternExample" />
    <h2>{`Color`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      